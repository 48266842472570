import styled, { DefaultTheme, css } from 'styled-components'
import { TextColor } from '@controls/Text'
import { ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'

type ButtonStyledProps = {
  $size: ButtonProps['size']
  $color?: TextColor
  $loading?: boolean
}

const buildFontSize = (theme: DefaultTheme, $size: ButtonProps['size']): string => {
  switch ($size) {
    case 'small':
      return theme.global.typography.size['size-14']
    case 'medium':
      return theme.global.typography.size['size-16']
    case 'large':
      return theme.global.typography.size['size-18']
    default:
      return theme.global.typography.size['size-18']
  }
}

const buildPadding = (theme: DefaultTheme, $size: ButtonProps['size']): string => {
  switch ($size) {
    case 'small':
      return theme.spacing(0.5, 1)
    case 'medium':
      return theme.spacing(1.0, 2)
    case 'large':
      return theme.spacing(1.5, 2.5)
    default:
      return theme.spacing(1.5, 2.5)
  }
}

const buildMinWidth = ($size: ButtonProps['size']): string => {
  switch ($size) {
    case 'small':
      return '36px'
    case 'medium':
      return '48px'
    case 'large':
      return '64px'
    default:
      return '64px'
  }
}

export const ButtonStyled = styled(Button)<ButtonStyledProps>(
  ({ theme, $color = 'black', $size, $loading }) => css`
    ${theme.mixins.flexCenter}

    color: ${theme.colors[$color]};
    border: 1px solid ${theme.colors[$color]};
    padding: ${buildPadding(theme, $size)};
    border-radius: 0;
    text-transform: none;
    font-size: ${buildFontSize(theme, $size)};
    min-width: ${buildMinWidth($size)};
    position: relative;
    transition: all 0.2s;
    font-weight: ${theme.typography.fontWeightRegular};
    &:hover,
    &:focus {
      background: ${theme.mixins.opacity(theme.colors[$color], 0.1)};
    }

    ${$loading &&
    css`
      &:disabled {
        cursor: not-allowed;
        color: transparent;
      }

      .btn-loader {
        ${theme.mixins.engulfAbsolute}
        ${theme.mixins.flexCenter}

        color: ${theme.colors[$color]};
      }
    `}
  `
)
