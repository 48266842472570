import * as React from 'react'
import { ButtonProps as MuiButtonProps } from '@mui/material'
import { CircleLoader } from '@controls/CircleLoader'
import { TextColor } from '@controls/Text'
import { ButtonStyled } from './style'

export type ButtonProps = Omit<MuiButtonProps, 'color'> &
  Readonly<{
    color?: TextColor
    loading?: boolean
  }>

export const Button: React.FC<ButtonProps> = ({ size, color, disabled, loading = false, children, ...props }) => {
  return (
    <ButtonStyled disabled={loading || disabled} $color={color} $loading={loading} $size={size} {...props}>
      {children}
      <CircleLoader loading={loading} circleProps={{ size: 20, thickness: 3 }} className="btn-loader" />
    </ButtonStyled>
  )
}
