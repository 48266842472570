import * as React from 'react'
import { BoxProps, CircularProgress, CircularProgressProps } from '@mui/material'
import { CircleLoaderStyled } from './CircleLoader.styles'

type CircleLoaderProps = BoxProps & {
  loading: boolean
  circleProps?: CircularProgressProps
  children?: React.ReactNode
}

export const CircleLoader = ({ loading, children, circleProps, ...props }: CircleLoaderProps): React.ReactElement => {
  return loading ? (
    <CircleLoaderStyled {...props}>
      <CircularProgress thickness={1.5} {...circleProps} />
    </CircleLoaderStyled>
  ) : (
    <>{children}</>
  )
}
