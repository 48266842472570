import styled, { css } from 'styled-components'
import { Box } from '@mui/material'

export const CircleLoaderStyled = styled(Box)`
  ${() => css`
    .MuiCircularProgress-svg,
    .MuiCircularProgress-root {
      color: currentColor;
    }
  `}
`
